import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'

import './index.css'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ImpressumPage = () => {
  const data = useStaticQuery(graphql`
    query {
      ort: file(relativePath: { eq: "wahl2020/kandidatenreihe.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg
        className='jumbotron jumbotron-fluid'
        style={{ backgroundPosition: 'top center' }}
        fluid={data.ort.childImageSharp.fluid}>
        <div className='container'>
          <h1
            className='display-4 text-light mobile-header'
            style={{ textShadow: '0 0 10px black', fontWeight: 'normal' }}>
            Impressum
          </h1>
        </div>
      </BGImg>
      <div className='container my-5'>
        <p>
          <span style={{ fontWeight: 'bold' }}>Angaben gem. § 5 TMG:</span>
          <br />
          Christlich-Soziale-Union in Bayern e.V.
          <br />
          Ortsverband Neutraubling
          <br />
          V.i.S.d.P.: Wolfgang Kessner (Ortsvorsitzender)
          <br />
          Bayerwaldstr. 21
          <br />
          93073 Neutralubling
        </p>

        <p>
          <a
            href='mailto:kontakt@csu-neutraubling.de'
            className='btn btn-primary'>
            kontakt@csu-neutraubling.de
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default ImpressumPage
